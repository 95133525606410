/* manrope-regular - latin */
/* @font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./manrope-v4-latin-regular.woff2') format('woff2'),
    url('./manrope-v4-latin-regular.woff') format('woff');
  font-display: 'swap';
} */
/* open-sans-regular - latin */
/* @font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./open-sans-v20-latin-regular.woff2') format('woff2'),
    url('./open-sans-v20-latin-regular.woff') format('woff');
  font-display: 'swap';
} */
/* bebas-neue-regular - latin */
@font-face {
  font-family: 'Bebas Neue';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./bebas-neue-v2-latin-regular.woff2') format('woff2'),
    url('./bebas-neue-v2-latin-regular.woff') format('woff');
  font-display: 'swap';
}
/* pacifico-regular - latin */
/* @font-face {
  font-family: 'Pacifico';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./pacifico-v17-latin-regular.woff2') format('woff2'),
    url('./pacifico-v17-latin-regular.woff') format('woff');
  font-display: 'swap';
} */
/* cinzel-700 - latin */
/* @font-face {
  font-family: 'Cinzel';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./cinzel-v11-latin-700.woff2') format('woff2'), url('./cinzel-v11-latin-700.woff') format('woff');
  font-display: 'swap';
} */
/* nunito-800 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  src: local(''), url('./nunito-v16-latin-800.woff2') format('woff2'), url('./nunito-v16-latin-800.woff') format('woff');
  font-display: 'swap';
}
/* roboto-slab-600 - latin */
@font-face {
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('./roboto-slab-v16-latin-600.woff2') format('woff2'),
    url('./roboto-slab-v16-latin-600.woff') format('woff');
  font-display: 'swap';
}
/* Lato - latin-ext woff2*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('./lato-v23-latin-ext-regular.woff2') format('woff2');
  unicode-range: U+0100-02AF, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* Lato - latin woff2*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('./lato-v23-latin-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0300-0301, U+0303-0304,
    U+0308-0309, U+0323, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Lato - latin ttf*/
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''), url('./lato-v23-latin-regular.ttf') format('truetype');
}
